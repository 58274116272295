@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
}

body {
  @apply bg-yt-black text-yt-text m-0 p-0;
  min-height: 100vh;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Custom scrollbar for sidebar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  @apply bg-yt-black;
}

::-webkit-scrollbar-thumb {
  @apply bg-yt-light rounded-full;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-yt-text-secondary;
}

/* Animation classes */
.fade-in {
  animation: fadeIn 0.3s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.hover-scale {
  transition: transform 0.2s ease-in-out;
}

.hover-scale:hover {
  transform: scale(1.05);
}